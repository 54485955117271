import { Link } from 'react-router-dom'
import { useAppSelector } from '../app/hooks'

const Navbar = () => {
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn)

  return (
    <nav className="fixed bottom-0 p-4 flex inset-x-0 bg-white w-full shadow-top z-10">
      {
        isLoggedIn &&
        <>
          <Link
            to="/"
            className="text-dark flex flex-col items-center text-xs uppercase mr-12 ml-8"
          >
            <svg className="w-8 h-8 text-light" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
              <path fill="currentColor" d="M4.38 19.223a.88.88 0 00.163 1.016l.215.215a38.485 38.485 0 012.223-2.412c.062-.062.125-.092.188-.149L5.786 16.51c-.804 1.454-1.272 2.447-1.407 2.713zM11.952 23.014c-.88.88-1.676 1.605-2.408 2.226l.291.292c.27.27.684.335 1.024.16.455-.235 1.171-.572 2.69-1.418l-1.447-1.448c-.057.062-.087.126-.15.188zM11.484 18.512c-.635-.635-2.421-.066-3.26.773-2.808 2.807-4.075 4.797-4.645 5.972a.878.878 0 001.176 1.174c1.148-.558 3.114-1.82 5.955-4.66.922-.923 1.377-2.655.774-3.258v-.001zM2.743 11.702L.257 14.188a.879.879 0 00.85 1.47c1.343-.36 2.525-.512 3.321-.308A47.157 47.157 0 018.066 9.8c-.745-.479-3.208-.212-5.323 1.902zM14.877 29.906a.877.877 0 001.016-.163l2.486-2.486c1.925-1.926 2.264-4.41 1.827-5.197a50.173 50.173 0 01-5.555 3.61c.123.692.041 1.363-.248 3.322-.057.38.139.746.474.914zM17.45 8.816a2.64 2.64 0 000 3.73 2.64 2.64 0 003.728 0 2.64 2.64 0 000-3.73 2.64 2.64 0 00-3.729 0zM29.948.857a.878.878 0 00-.81-.81c-2.16-.163-4.31.103-6.417.747.159 1.448.907 3.015 2.186 4.294C26.201 6.38 27.81 7.13 29.284 7.27c.568-1.99.837-4.12.664-6.413z" />
              <path fill="currentColor" d="M23.664 6.33c-1.462-1.462-2.355-3.216-2.642-4.924C15.744 3.57 10.692 8.297 6.7 14.938l1.983 1.983c1.355-.55 3-.695 4.044.348 1.045 1.045.897 2.69.347 4.043l2.048 2.048c5.11-3.079 11.058-7.93 13.587-14.384-1.75-.264-3.558-1.16-5.045-2.645zm-1.243 7.458a4.4 4.4 0 01-6.215 0 4.4 4.4 0 010-6.215 4.4 4.4 0 016.215 0 4.4 4.4 0 010 6.215z" />
            </svg>
            Проекти
          </Link>
          <Link
            to="/clients"
            className="text-dark flex flex-col items-center text-xs uppercase"
          >
            <svg className="w-8 h-8 text-light" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 19">
              <path fill="currentColor" d="M15.007 7.488a2.895 2.895 0 002.891-2.892V2.892a2.892 2.892 0 10-5.783 0v1.704a2.895 2.895 0 002.892 2.892zM24.978 8.604a2.47 2.47 0 002.466-2.467V4.645a2.467 2.467 0 00-4.933 0v1.492a2.47 2.47 0 002.467 2.467zM5.022 8.604A2.47 2.47 0 007.49 6.137V4.645a2.467 2.467 0 00-4.933 0v1.492a2.47 2.47 0 002.466 2.467zM30 13.597a2.844 2.844 0 00-2.42-2.81 3.408 3.408 0 01-5.103-.013 2.796 2.796 0 00-1.103.364c.315.613.479 1.292.478 1.98v3.318H30v-2.84zM9.196 13.118v5.023h11.622v-5.023a3.322 3.322 0 00-2.867-3.288 3.82 3.82 0 01-5.77-.013 3.342 3.342 0 00-2.985 3.301zM0 13.597v2.84h8.161v-3.319a4.333 4.333 0 01.473-1.975 2.825 2.825 0 00-1.01-.356 3.408 3.408 0 01-5.102-.013A2.86 2.86 0 000 13.597z" />
            </svg>
            Клиенти
          </Link>
        </>
      }
    </nav>)
}

export default Navbar
