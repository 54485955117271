import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Client } from '../../interfaces/ClientInterface'
import { getDatabase, ref, get, child, update, push } from "firebase/database";
import firebaseApp from "../../firebase"

const db = getDatabase(firebaseApp)
const dbref = ref(db)

const initialState: { loading: boolean, clients: Client[], error: string } = {
  loading: false,
  clients: [],
  error: ''
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClients: (state, action) => {
      state.clients = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchClients.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchClients.fulfilled, (state, action: PayloadAction<Client[]>) => {
      state.loading = false
      state.clients = action.payload
      state.error = ''
    })
    builder.addCase(fetchClients.rejected, (state, action) => {
      state.loading = true
      state.clients = []
      state.error = action.error.message || "Нещо се обърка."
    })

    // Add New Client
    builder.addCase(addNewClient.pending, state => {
      state.loading = true
    })
    builder.addCase(addNewClient.fulfilled, (state, action: PayloadAction<Client>) => {
      state.clients.push(action.payload)
      state.error = ''
      state.loading = false
    })
    builder.addCase(addNewClient.rejected, (state, action) => {
      state.error = action.error.message || "Нещо се обърка."
      state.loading = false
    })
  }
})

export const addNewClient = createAsyncThunk('clients/addNewClient',
  async (client: Client) => {
    const newKey: string | null = push(child(dbref, 'clients')).key
    const updates = {} as string | any
    updates[`/clients/${newKey}`] = client
    return await update(dbref, updates)
      .then(() => {
        if (newKey)
          client['id'] = newKey
        return client
      })
  })

export const fetchClients = createAsyncThunk('clients/fetchClients',
  async () => {
    const database = getDatabase(firebaseApp);
    const dbref = ref(database);

    return await get(child(dbref, "clients"))
      .then(snapshot => {
        const obj = snapshot.val();
        const clients: Client[] = [];
        for (let key in obj) {
          clients.push({
            id: key,
            name: obj[key].name,
            email: obj[key].email
          });
        }
        return clients
      })
  })

export const { setClients } = clientsSlice.actions
export default clientsSlice.reducer
