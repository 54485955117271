import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../app/hooks"
import { addNewClient } from "../../features/clients/clientsSlice"

export const CreateClient = () => {

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const onHandleName = (e: React.ChangeEvent<HTMLInputElement>) => { setName(e.target.value) }
  const onHandleEmail = (e: React.ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value) }
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const client = {
      id: '',
      name: name,
      email: email
    }

    dispatch(addNewClient(client))

    setName("")
    setEmail("")
  }

  const disableSubmit = (): boolean => name === "" || email === ""

  return (
    <div className="relative">
      <div className="w-full h-full fixed bg-opacity inset-0"></div>
      <div className="transform w-full bg-lighter pb-32 z-50 bg-white shadow-outer rounded-lg px-4 py-2 flex flex-col items-center text-dark justify-between fixed bottom-0 left-0 transition duration-500 ease-in-out translate-y-0">
        <h4 className="font-hairline text-2xl mb-2">Нов клиент</h4>
        <form onSubmit={onSubmit} className="w-full" >

          <fieldset>
            <input
              className="appearance-none block w-full bg-white border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-4"
              type="text"
              aria-describedby="nameHelp"
              placeholder="Име"
              onChange={onHandleName}
              required
            />
          </fieldset>
          <fieldset>
            <input
              className="mb-4 block appearance-none w-full bg-white border py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="email"
              aria-describedby="emailHelp"
              placeholder="Ел. поща"
              onChange={onHandleEmail}
              required
            />
          </fieldset>
          <button
            disabled={disableSubmit()}
            type="submit"
            className={`z-50 fixed right-0 bottom-0 w-20 h-20 rounded-full text-white text-6xl mr-4 mb-8 flex justify-center items-center ${disableSubmit() ? " bg-dark" : " bg-green"}`}
          >
            <svg className="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 22">
              <path fill="#fff" d="M27 0L11.306 13.947 3.859 6.524 0 10.382 11.318 21.7 31 4.347 27 0z" />
            </svg>
          </button >
        </form >

        <button
          onClick={() => navigate(-1)}
          className="z-50 absolute right-0 top-0 w-10 h-10 rounded-full bg-dark text-white text-4xl mr-4 mb-8 flex justify-center items-center -mt-4"
        >
          <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="50.912" height="50.912" viewBox="0 0 50.912 50.912"><path fill="currentColor" d="m33.941 25.456 16.97-16.97L42.427 0l-16.97 16.97L8.486 0 0 8.485l16.97 16.97L0 42.427l8.485 8.486 16.97-16.97 16.971 16.97 8.486-8.486Z" /></svg>
        </button >
      </div >
    </div >
  )
}
