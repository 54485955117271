import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import Header from "../../components/Header"
import { formatPrice, getAllArchiveProjectsPrice, getAllProjectsPrice, getPrice, ownProjects } from "../../helpers"
import { Client } from "../../interfaces/ClientInterface"
import { Project } from "../../interfaces/ProjectInterface"
import { Task } from "../../interfaces/TaskInterface"

type RouteParams = {
  id: string
}

const ClientDetails = () => {

  const projects = useAppSelector((state) => state.projects.projects)
  const clients = useAppSelector((state) => state.clients.clients)
  const { id } = useParams<keyof RouteParams>() as RouteParams;
  const [client, setClient] = useState<Client | null>(null)
  const [totalPrice, setTotalPrice] = useState('0')
  const [totalActivePrice, setTotalActivePrice] = useState('0')
  const [allActiveProjects, setallActiveProjects] = useState<Project[]>([])
  const [allArchivedProjects, setallAllArchivedProjects] = useState<Project[]>([])

  useEffect(() => {
    setTotalPrice(client ? formatPrice(getAllProjectsPrice(ownProjects(projects, client.id))) : '0')
    setTotalActivePrice(client ? formatPrice(getAllArchiveProjectsPrice(ownProjects(projects, client.id, false))) : '0')
    setallActiveProjects(client ? ownProjects(projects, client.id) : [])
    setallAllArchivedProjects(client ? ownProjects(projects, client.id, false) : [])
  }, [projects, client])

  const price = (tasks: Task[], add_taxes: boolean) => formatPrice(getPrice(tasks, add_taxes))

  useEffect(() => {
    const client = clients.find(el => el.id === id)
    if (client)
      setClient(client)
  }, [clients])

  return (
    <div>
      <Header
        title={client?.name}
        price={totalPrice}
      />
      <div className="p-4 pb-32">
        <div className="bg-white shadow-outer rounded-lg px-4 py-2 mb-4">
          <p>
            <a href={`mailto:${client?.email}`}>{client?.email}</a>
          </p>
          <h4 className="mt-4 mb-10 border-b text-dark font-black text-2xl">Проекти</h4>
          <div className="mb-6">
            <h3 className="mb-2 mt-4 font-bold border-b text-lg">Активни</h3>
            {
              allActiveProjects.map((project:Project, idx:number) => (
                <Link
                  key={idx}
                  to={`/project/${project.id}`}
                  className="bg-transparent border-b last:border-b-0 text-left relative w-full pr-4 py-2 flex text-dark justify-between items-start mb-2 last:mb-0"
                >
                  <small className="font-hairline text-gray-200 leading-none text-4xl mr-2">
                    {idx + 1}
                  </small>
                  <p className="mr-4 flex-1">{project.title}</p>
                  <p className="text-xl font-black flex-none text-blue">
                    {price(project.tasks, project.add_taxes)} лв.
                  </p>
                </Link>
              ))
            }
            {
              allActiveProjects.length === 0 &&
              <p>Няма активни проекти!</p>
            }
            <h3 className="mb-2 mt-16 border-b font-bold text-lg flex justify-between">Архив<span className="text-2xl">{totalActivePrice} лв.</span></h3>
            {
              allArchivedProjects.map((project:Project, idx:number) => (
                <Link
                  key={idx}
                  to={`/project/${project.id}`}
                  className="bg-transparent border-b last:border-b-0 text-left relative w-full pr-4 py-2 flex text-dark justify-between items-start mb-2 last:mb-0"
                >
                  <small className="font-hairline text-gray-200 leading-none text-4xl mr-2">
                    {idx + 1}
                  </small>
                  <p className="mr-4 flex-1">{project.title}</p>
                  <p className="text-xl font-black flex-none text-blue">
                    {price(project.tasks, project.add_taxes)} лв.
                  </p>
                </Link>
              ))
            }
            {
              allArchivedProjects.length === 0 &&
              <p>Няма архивирани проекти!</p>
            }
          </div >
        </div >
      </div >
    </div >
  )
}

export default ClientDetails
