import { Client } from "./interfaces/ClientInterface"
import { Project } from "./interfaces/ProjectInterface"
import { Task } from "./interfaces/TaskInterface"

export const getPrice = (tasks: Array<Task>, calcTaxes: boolean = false, excludePaied: boolean = false): number => {
  let income = tasks.reduce((a, x) => (x.price >= 0 ? a + x.price : a), 0)
  let paied = tasks.reduce((a, x) => (x.price < 0 ? a + x.price : a), 0)
  let taxes = calcTaxes ? income * 0.9 * 0.95 : income
  return tasks.length === 0 ? 0 : taxes + (excludePaied ? 0 : paied)
};

export const getAllProjectsPrice = (projects: Array<Project>, excludeArchives: boolean = false) =>
  projects.reduce((a, project) => a + (project.archive === excludeArchives ? getPrice(project.tasks, !!project.add_taxes) : 0), 0)

export const getAllArchiveProjectsPrice = (projects: Array<Project>) =>
  projects.reduce((a, project) => a + (project.archive ? getPrice(project.tasks, !!project.add_taxes, true) : 0), 0)

export const getAllClientsPrice = (projects: Array<Project>, clients: Array<Client>) =>
  clients.reduce(
    (a, project) => a + getAllProjectsPrice(ownProjects(projects, project.id)),
    0
  );

export const ownProjects = (projects: Array<Project>, clientId: string, excludeArchives: boolean = true) => {
  if (clientId)
    return projects.filter(project => project.client_id === clientId && project.archive != excludeArchives)
  return []
}

export const formatPrice = (price: number) =>
  new Intl.NumberFormat("bg-BG").format(parseFloat(price.toFixed(2)))

