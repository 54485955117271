import { useEffect, useState } from "react"
import { Link, Outlet, useParams } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import CustonLink from "../../components/CustomLink"
import Header from "../../components/Header"
import { formatPrice, getPrice } from "../../helpers"
import { Client } from "../../interfaces/ClientInterface"
import { Project } from "../../interfaces/ProjectInterface"
import { Task } from "../../interfaces/TaskInterface"

type RouteParams = {
  id: string
}

const ProjectDetails = () => {

  const projects = useAppSelector((state) => state.projects.projects)
  const clients = useAppSelector((state) => state.clients.clients)
  const { id } = useParams<keyof RouteParams>() as RouteParams;
  const [project, setProject] = useState<Project | null>(null)
  const [client, setClient] = useState<Client | null>(null)
  const [taxes, setTaxes] = useState<boolean>(false)

  const tasks = () => project?.tasks
    ? project.tasks.filter((task: Task) => task !== undefined)
    : []

  const allPrice = () => project?.tasks
    ? formatPrice(getPrice(project.tasks, project.add_taxes))
    : '0'

  const price = (taskPrice: number): string => formatPrice(taskPrice)

  const copy = () => {
    let copyText = project?.tasks.map(el => {
      if (el !== undefined) return `${el.body} - ${el.price} лв.` + "\n";
      return ''
    });
    if (copyText) {
      let result = copyText.join("");
      let price = project?.tasks.reduce((n, { price }) => n + price, 0);
      navigator.clipboard.writeText(
        `${result}\nОбщо: ${price} лв.`
      );
    }
  }

  useEffect(() => {
    if (projects && clients) {
      const project: Project | undefined = projects.find(el => el.id === id)
      if (project) {
        setProject(project)
        setClient(clients.find(el => el.id === project?.client_id) || null)
      }
    }
  }, [projects, clients, id])

  useEffect(() => {
    setTaxes(!!project?.add_taxes)
  }, [project?.add_taxes])

  return (
    <div>
      <Header
        title={project?.title}
        projectId={id}
        price={allPrice()}
        simple={true}
        client={client}
        taxes={taxes} />
      {
        tasks().length > 0 &&
        <button
          onClick={copy}
          className="bg-dark text-white w-24 h-12 rounded-full flex justify-center items-center gap-2 mt-4 ml-4 hover:bg-light">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488.3 488.3" fill="#fff">
            <path d="M314.25 85.4h-227c-21.3 0-38.6 17.3-38.6 38.6v325.7c0 21.3 17.3 38.6 38.6 38.6h227c21.3 0 38.6-17.3 38.6-38.6V124c-.1-21.3-17.4-38.6-38.6-38.6zm11.5 364.2c0 6.4-5.2 11.6-11.6 11.6h-227c-6.4 0-11.6-5.2-11.6-11.6V124c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.6z" />
            <path d="M401.05 0h-227c-21.3 0-38.6 17.3-38.6 38.6 0 7.5 6 13.5 13.5 13.5s13.5-6 13.5-13.5c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.7c0 6.4-5.2 11.6-11.6 11.6-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5c21.3 0 38.6-17.3 38.6-38.6V38.6c0-21.3-17.3-38.6-38.6-38.6z" />
          </svg>
          copy
        </button>
      }
      <div className="p-4 pb-32">
        {
          tasks().length > 0 &&
          <div className="bg-white shadow-outer rounded-lg px-4 py-2 mb-4">
            {
              tasks().map((task: Task, idx: number) => (
                <Link
                  key={idx}
                  to={`/project/${id}/task/${idx}`}
                  className="bg-transparent border-b last:border-b-0 text-left relative w-full pr-4 py-2 flex text-dark justify-between items-start mb-2 last:mb-0">
                  <small className="font-hairline text-gray-200 leading-none text-5xl mr-2">
                    {idx + 1}
                  </small>
                  <p className="mr-4 flex-1">{task.body}</p>
                  <p className={`text-xl font-black flex-none ${task.price < 0 ? 'text-red' : 'text-blue'}`}>
                    {`${price(task.price)} лв.`}
                  </p>
                </Link>
              ))
            }
          </div >
        }
      </div >
      <Outlet />
      <CustonLink to={`/project/${id}/task/create`} />
    </div >
  )
}

export default ProjectDetails
