import { AuthState } from "../../interfaces/UserInterface";
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { AppDispatch } from "../../app/store";

const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  error: ''
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(loggingIn.pending, state => {
      state.loading = true
    })
    builder.addCase(loggingIn.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.loading = false
        state.isLoggedIn = action.payload
        state.error = ''
      })
    builder.addCase(loggingIn.rejected, (state, action) => {
      state.loading = true
      state.isLoggedIn = false
      state.error = action.error.message || "Нещо се обърка."
    })
  }
})

export const setAuthListener = () => (dispatch: AppDispatch) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    dispatch(login(!!user))
    // return !!user
  });
}

export const loggingIn = createAsyncThunk('auth/loggingIn',
  async ({ email, password }: { email: string, password: string }) => {
    const auth = getAuth()
    return await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        return !!userCredential.user
      })
  })

export const { login } = authSlice.actions
export default authSlice.reducer
