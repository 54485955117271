import React from "react"

const Loading = ({ loading }: { loading: boolean }) => {
  return (
    <>
      {
        loading &&
        <div className="fixed z-50 inset-0 flex items-center justify-center bg-white">
          <div className="lds-ring inline-block relative w-16 h-16">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      }
    </>
  )
}

export default Loading
