import { formatPrice, getPrice } from '../../helpers';
import { Project } from '../../interfaces/ProjectInterface';
import { Client } from '../../interfaces/ClientInterface'
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';

type ClientItemProps = {
  client: Client
}

export const ClientListItem = ({ client }: ClientItemProps) => {

  const projects = useAppSelector((state) => state.projects.projects)

  const [allProjects, setAllProjects] = useState<Project[]>([])

  const getAllClientProjectsPrice = () => {
    let price: number = 0
    allProjects.forEach((project: Project) => {
      if (project.tasks && !project.archive)
        price += getPrice(project.tasks, project.add_taxes);
    })
    return formatPrice(price)
  }

  useEffect(() => {
    setAllProjects(projects.filter((el: Project) => el.client_id === client.id))
  }, [projects])

  return (
    <div className="mb-8 w-full">
      <Link
        to={`/client/${client.id}`}
        className="flex flex-1 items-end justify-between border-b border-gray mb-2 pb-1"
      >
        <p className="text-dark">
          <span className="font-black text-lg">{client.name}</span>
          <span className="text-sm block">
            {allProjects.length}
            {allProjects.length === 1 ? " проект" : " проекта"}
          </span>
        </p>
        <p className="text-blue text-lg font-black">{`${getAllClientProjectsPrice()} лв.`}</p>
      </Link>
    </div>
  )
}
