import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

export const RequireAuth = () => {
  const location = useLocation()
  const isLogedIn = useAuth()

  return (
    isLogedIn
      ? <Outlet />
      : <Navigate to="/login" state={{ from: location }} replace />
  )
}
