import { Link } from "react-router-dom"
import { StyleEnum } from "../app/enums"

type PropsType = {
    to: string
    style?: StyleEnum
}

const CustonLink = ({to, style = StyleEnum.GREEN}:PropsType) => {
    return (
        <Link
            to={to}
            className={`z-40 fixed right-0 bottom-0 w-20 h-20 rounded-full ${style} text-white flex justify-center items-center mr-4 mb-8`}
        >
            <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"><path fill="currentColor" d="M38 26V2H26v24H2v12h24v24h12V38h24V26z" /></svg>
        </Link>
    )
}

export default CustonLink