import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addTask, deleteTask, updateTask } from "../../features/projects/projectsSlice";
import { Project } from "../../interfaces/ProjectInterface";

type RouteParams = {
  id?: string
  idx?: string
}

const TaskForm = () => {
  const { id, idx } = useParams<keyof RouteParams>() as RouteParams;

  const projects = useAppSelector((state) => state.projects.projects)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [body, setBody] = useState("")
  const [price, setPrice] = useState(0)

  const disableSubmit = (): boolean => body === ""

  useEffect(() => {
    if (idx) {
      const project: Project | undefined = projects.find(prj => prj.id === id)
      if (project) {
        setBody(project.tasks[Number(idx)].body)
        setPrice(project.tasks[Number(idx)].price)
      }
    }
  }, [id, idx, projects])

  const onHandleBody = (e: React.ChangeEvent<HTMLTextAreaElement>) => { setBody(e.target.value) }
  const onHandlePrice = (e: React.ChangeEvent<HTMLInputElement>) => { setPrice(Number(e.target.value)) }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (idx) {
      dispatch(updateTask({
        task: {
          body,
          price
        },
        taskIndex: parseInt(idx),
        projectId: id
      }))
      navigate(-1)
    } else {
      dispatch(addTask({
        projectId: id,
        task: {
          body,
          price
        }
      }))
    }
    resetFields()
  }

  const onRemove = () => {
    if (idx !== undefined) {
      dispatch(deleteTask({ projectId: id, taskIndex: parseInt(idx) }))
      navigate(-1)
    }
  }

  const resetFields = () => {
    setBody("")
    setPrice(0)
  }

  return (
    <div className="relative">
      <div className="w-full h-full fixed bg-opacity inset-0"></div>
      <div className="transform w-full bg-lighter pb-32 z-50 bg-white shadow-outer rounded-lg px-4 py-2 flex flex-col items-center text-dark justify-between fixed bottom-0 left-0 transition duration-500 ease-in-out translate-y-0" >
        <h4 className="font-hairline text-2xl mb-2">
          {idx ? "Промяна на задача" : "Нова задача"}
        </h4>
        <form onSubmit={onSubmit} className="w-full">
          <fieldset>
            <textarea
              className="appearance-none block w-full bg-white border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-4"
              value={body}
              onChange={onHandleBody}
              placeholder="Описание"
              required
            ></textarea>
            <input
              className="w-full border p-2 rounded mb-2"
              type="number"
              value={price}
              onChange={onHandlePrice}
              placeholder="Цена"
              required
            />
            <button
              disabled={disableSubmit()}
              type="submit"
              className={`${disableSubmit() ? 'bg-dark' : 'bg-green hover:bg-green-dark'} z-40 fixed right-0 bottom-0 w-20 h-20 rounded-full text-white text-6xl mr-4 mb-8 flex justify-center items-center`}
            >
              <svg className="w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 31 22" >
                <path fill="#fff" d="M27 0L11.306 13.947 3.859 6.524 0 10.382 11.318 21.7 31 4.347 27 0z" />
              </svg>
            </button>
            {idx &&
              <button
                onClick={onRemove}
                className="z-40 fixed left-0 bottom-0 w-20 h-20 rounded-full bg-red text-white text-6xl ml-4 mb-8 flex justify-center items-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 473 473" fill="#fff">
                  <path d="M324.285 215.015V128h20V38h-98.384V0H132.669v38H34.285v90h20v305h161.523c23.578 24.635 56.766 40 93.477 40 71.368 0 129.43-58.062 129.43-129.43 0-66.294-50.103-121.096-114.43-128.555zm-30 0a128.499 128.499 0 0 0-50 16.669V128h50v87.015zM162.669 30h53.232v8h-53.232v-8zM64.285 68h250v30h-250V68zm20 60h50v275h-50V128zm80 275V128h50v127.768c-21.356 23.089-34.429 53.946-34.429 87.802 0 21.411 5.231 41.622 14.475 59.43h-30.046zm145 40c-54.826 0-99.429-44.604-99.429-99.43s44.604-99.429 99.429-99.429 99.43 44.604 99.43 99.429S364.111 443 309.285 443z" />
                  <path d="m342.248 289.395-32.963 32.963-32.962-32.963-21.213 21.213 32.963 32.963-32.963 32.962 21.213 21.213 32.962-32.963 32.963 32.963 21.213-21.213-32.963-32.962 32.963-32.963z" />
                </svg>
              </button>
            }
          </fieldset>
        </form >
        <button
          onClick={() => navigate(-1)}
          className="z-50 absolute right-0 top-0 w-10 h-10 rounded-full bg-dark text-white text-4xl mr-4 mb-8 flex justify-center items-center -mt-4"
        >
          <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="50.912" height="50.912" viewBox="0 0 50.912 50.912"><path fill="currentColor" d="m33.941 25.456 16.97-16.97L42.427 0l-16.97 16.97L8.486 0 0 8.485l16.97 16.97L0 42.427l8.485 8.486 16.97-16.97 16.971 16.97 8.486-8.486Z" /></svg>
        </button >
      </div >
    </div>
  )
}

export default TaskForm
