import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch } from "../app/hooks"
import { addTaxes, archiveProject } from "../features/projects/projectsSlice"
import { Client } from "../interfaces/ClientInterface"

type HeaderProps = {
  projectId?: string
  taxes?: boolean
  simple?: boolean
  price: string
  title?: string
  client?: Client | null
}

const Header = (props: HeaderProps) => {

  const [showTaxes, setShowTaxes] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const onChangeTaxes = () => {
    dispatch(addTaxes({ projectId: props.projectId, add_taxes: !props.taxes }))
  }

  const archive = () => {
    dispatch(archiveProject({ projectId: props.projectId }));
    navigate("/", { replace: true })
  }

  useEffect(() => {
    setShowTaxes(props.taxes !== undefined);
  }, [props.taxes])

  return (
    <div className="flex flex-col p-4 bg-center bg-cover" style={{ backgroundImage: 'url("/images/background.png")' }} >
      < div className="flex justify-between items-baseline mb-2" >
        <div className="flex-column">
          <h1 className={`font-hairline text-white ${!props.simple ? 'uppercase text-4xl my-0 mt-0' : 'text-2xl mt-3'}`} >
            {props.title}
          </h1>
          {
            props.client &&
            <Link
              to={`/client/${props.client?.id}`}
              className="text-white text-sm mb-6 inline-block hover:underline"
            >
              {props.client.name}
            </Link>
          }
        </div>
        <div className="text-right">
          <p className="text-white text-2xl flex-none">{`${props.price} лв.`}</p>
          {
            showTaxes &&
            <p className="text-white text-sm flex-none">
              <input
                onChange={onChangeTaxes}
                checked={props.taxes}
                type="checkbox"
                id="taxes"
                className="mr-2"
              />
              <label htmlFor="taxes">С отчислени такси</label>
            </p>
          }
        </div>
      </div >

      {
        props.simple &&
        <button
          onClick={archive}
          className="bg-dark text-white w-12 h-12 rounded-full flex justify-center items-center hover:bg-light"
        >
          < svg className="w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 27" >
            <path
              d="M13 2H7.64c-.488 0-.94.256-1.19.674L.2 13.091a1.384 1.384 0 00-.198.621c-.004.032 0 10.51 0 10.51A2.78 2.78 0 002.779 27h27.775a2.78 2.78 0 002.777-2.778V23.9c.001-1.762.004-10.16 0-10.187a1.385 1.385 0 00-.198-.622L26.884 2.674A1.388 1.388 0 0025.694 2H21v2.778h3.907l5 8.333h-3.52a2.78 2.78 0 00-2.777 2.778 1.39 1.39 0 01-1.389 1.389h-11.11a1.39 1.39 0 01-1.388-1.39 2.78 2.78 0 00-2.777-2.777h-3.52l5-8.333H13V2z" />
            <path d="M19 0h-4v9h-2.878L12 9.143 17 15l5-5.857L21.878 9H19V0z" />
          </svg>
        </button>
      }
    </div >)
}

export default Header
