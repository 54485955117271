import { useAppSelector } from "../../app/hooks"
import Header from "../../components/Header"
import { formatPrice, getAllProjectsPrice } from "../../helpers"
import { Project } from "../../interfaces/ProjectInterface"
import { Client } from "../../interfaces/ClientInterface"
import { ClientListItem } from "./ListItem"
import { Outlet } from "react-router-dom"
import CustonLink from "../../components/CustomLink"
import { StyleEnum } from "../../app/enums"

export const ClientsList = () => {
  const projects = useAppSelector((state) => state.projects.projects)
  const clients = useAppSelector((state) => state.clients.clients)

  const totalPrice = (): string => formatPrice(getAllProjectsPrice(projects))

  const getProjects = (id: string, isArchive: boolean = false): Project[] => {
    if (projects.length)
      return projects.filter(element => element.client_id === id && isArchive)
    return []
  }

  // const allClientsWithActiveProjects = (): Client[] => clients.filter(
  //   client => getProjects(client.id).length > 0
  // )

  return (
    <div>
      <Header title="Клиенти" price={totalPrice()} />
      <div className="p-4 pb-32">
        <div className="bg-white shadow-outer rounded-lg px-4 py-2 mb-4">
          {
            clients.map((client: Client) => (
              <ClientListItem
                client={client}
                key={client.id}
              />
            ))
          }
        </div>
      </div>
      <Outlet />
      <CustonLink to="/clients/create" style={StyleEnum.GREEN} />
    </div >
  )
}
