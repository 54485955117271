import { formatPrice, getAllProjectsPrice, getPrice } from '../../helpers';
import { Project } from '../../interfaces/ProjectInterface';
import { Task } from '../../interfaces/TaskInterface';
import { Client } from '../../interfaces/ClientInterface'
import { Link } from 'react-router-dom';

type ItemProps = {
  client: Client
  projects: Project[]
}

export const ListItem = ({ client, projects }: ItemProps) => {

  const finalPrice = () => {
    return formatPrice(getAllProjectsPrice(projects));
  }

  const price = (tasks: Array<Task>, add_taxes: boolean) => {
    return formatPrice(getPrice(tasks, add_taxes));
  }

  return (
    <div className="mb-8">
      <Link to={`/client/${client.id}`}
        className="flex items-end justify-between border-b border-gray mb-2 pb-1"
      >
        <p className="text-dark font-black text-lg">{client.name}</p>
        <p className="text-blue text-lg font-black">{`${finalPrice()} лв.`}</p>
      </Link>
      {
        projects.map((project: Project) => (
          <Link
            key={project.id}
            to={`/project/${project.id}`}
            className="flex items-center text-dark justify-between mb-2 last:mb-0"
          >
            <p>{project.title}</p>
            <p className="text-blue flex-none">
              {`${price(project.tasks, project.add_taxes)} лв.`}
            </p>
          </Link>
        ))
      }
    </div >
  )
}
