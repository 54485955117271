import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IaddTaxes,
  IarchiveProject,
  Project,
} from "../../interfaces/ProjectInterface";
import { getDatabase, ref, get, child, push, update, serverTimestamp } from "firebase/database";
import firebaseApp from "../../firebase";
import {
  IaddTask,
  IdeleteTask,
  IupdateTask,
  Task,
} from "../../interfaces/TaskInterface";

const initialState: { loading: boolean; projects: Project[]; error: string } = {
  loading: false,
  projects: [],
  error: "",
};

const getProject = (
  projects: Project[],
  id: string | undefined
): Project | undefined => projects.find((el) => el.id === id);
const db = getDatabase(firebaseApp);
const dbref = ref(db);

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    archiveProjectdel: (state, action) => {
      const project = getProject(state.projects, action.payload.projectId);
      if (project) {
        project.archive = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    // Fetch All Projects
    builder.addCase(fetchProjects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchProjects.fulfilled,
      (state, action: PayloadAction<Project[]>) => {
        state.loading = false;
        state.projects = action.payload;
        state.error = "";
      }
    );
    builder.addCase(fetchProjects.rejected, (state, action) => {
      state.loading = false;
      state.projects = [];
      state.error = action.error.message || "Нещо се обърка.";
    });

    // Add New Project
    builder.addCase(addNewProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      addNewProject.fulfilled,
      (state, action: PayloadAction<Project>) => {
        state.projects.push(action.payload);
        state.error = "";
        state.loading = false;
      }
    );
    builder.addCase(addNewProject.rejected, (state, action) => {
      state.error = action.error.message || "Нещо се обърка.";
      state.loading = false;
    });

    // Add Taxes
    builder.addCase(addTaxes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      addTaxes.fulfilled,
      (state, action: PayloadAction<IaddTaxes>) => {
        state.projects.forEach((obj) => {
          if (obj.id === action.payload.projectId)
            obj.add_taxes = !obj.add_taxes;
        });
        state.error = "";
        state.loading = false;
      }
    );
    builder.addCase(addTaxes.rejected, (state, action) => {
      state.projects = [];
      state.error = action.error.message || "Нещо се обърка.";
      state.loading = false;
    });

    // Add Task
    builder.addCase(addTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      addTask.fulfilled,
      (state, action: PayloadAction<IaddTask>) => {
        const project = getProject(state.projects, action.payload.projectId);
        if (project) {
          state.projects.forEach((obj) => {
            if (obj.id === action.payload.projectId)
              obj.tasks.push(action.payload.task);
          });
        }
        state.loading = false;
        state.error = "";
      }
    );
    builder.addCase(addTask.rejected, (state, action) => {
      state.projects = [];
      state.error = action.error.message || "Нещо се обърка.";
      state.loading = false;
    });

    // Delete Task
    builder.addCase(deleteTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      deleteTask.fulfilled,
      (state, action: PayloadAction<IdeleteTask>) => {
        const project = getProject(state.projects, action.payload.projectId);
        if (project?.tasks) project.tasks.splice(action.payload.taskIndex, 1);
        state.loading = false;
        state.error = "";
      }
    );
    builder.addCase(deleteTask.rejected, (state, action) => {
      state.projects = [];
      state.error = action.error.message || "Нещо се обърка.";
      state.loading = false;
    });

    // Update Task
    builder.addCase(updateTask.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateTask.fulfilled,
      (state, action: PayloadAction<IupdateTask>) => {
        const project = getProject(state.projects, action.payload.projectId);
        if (project) {
          project.tasks[action.payload.taskIndex] = { ...action.payload.task };
        }
        state.loading = false;
        state.error = "";
      }
    );
    builder.addCase(updateTask.rejected, (state, action) => {
      state.projects = [];
      state.error = action.error.message || "Нещо се обърка.";
      state.loading = false;
    });

    // Archive Project
    builder.addCase(archiveProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      archiveProject.fulfilled,
      (state, action: PayloadAction<IarchiveProject>) => {
        const project = getProject(state.projects, action.payload.projectId);
        if (project) {
          const result: Project[] = state.projects.filter(
            (project) => project.id !== action.payload.projectId
          );
          state.projects = result;
        }
        state.loading = false;
        state.error = "";
      }
    );
    builder.addCase(archiveProject.rejected, (state, action) => {
      state.projects = [];
      state.error = action.error.message || "Нещо се обърка.";
      state.loading = false;
    });
  },
});

export const archiveProject = createAsyncThunk(
  "projects/archiveProject",
  async (data: IarchiveProject, { getState }) => {
    const state: any = getState();
    const updates = {} as string | any;
    const project: Project = state.projects.projects.find(
      (el: Project) => el.id === data.projectId
    );
    updates[`/projects/${data.projectId}`] = { ...project, archive: true, paid_date: serverTimestamp()};
    return await update(dbref, updates).then(() => {
      return { projectId: data.projectId };
    });
  }
);

export const updateTask = createAsyncThunk(
  "projects/updateTask",
  async (data: IupdateTask, { getState }) => {
    const state: any = getState();
    const updates = {} as string | any;
    const project: Project = state.projects.projects.find(
      (el: Project) => el.id === data.projectId
    );
    let tasks: Task[] = [];
    if (project) {
      tasks = [...project.tasks];
      tasks[data.taskIndex] = { ...data.task };
    }
    updates[`/projects/${data.projectId}`] = { ...project, tasks: tasks };
    return await update(dbref, updates).then(() => {
      return {
        projectId: data.projectId,
        task: data.task,
        taskIndex: data.taskIndex,
      };
    });
  }
);

export const deleteTask = createAsyncThunk(
  "projects/deleteTask",
  async (data: IdeleteTask, { getState }) => {
    const state: any = getState();
    const updates = {} as string | any;
    const project: Project = state.projects.projects.find(
      (el: Project) => el.id === data.projectId
    );
    let tasks: Task[] = [...project.tasks];
    if (project) {
      tasks.splice(data.taskIndex, 1);
    }
    updates[`/projects/${data.projectId}`] = { ...project, tasks: tasks };
    return await update(dbref, updates).then(() => {
      return { projectId: data.projectId, taskIndex: data.taskIndex };
    });
  }
);

export const addTask = createAsyncThunk(
  "projects/addTask",
  async (data: IaddTask, { getState }) => {
    const state: any = getState();
    const updates = {} as string | any;
    const project: Project = state.projects.projects.find(
      (el: Project) => el.id === data.projectId
    );
    let tasks: Task[] = [];
    if (project) {
      tasks = [...project.tasks, data.task];
    }
    updates[`/projects/${data.projectId}`] = { ...project, tasks: tasks };
    return await update(dbref, updates).then(() => {
      return { projectId: data.projectId, task: data.task };
    });
  }
);

export const addTaxes = createAsyncThunk(
  "projects/addTaxes",
  async (data: IaddTaxes, { getState }) => {
    const state: any = getState();
    const updates = {} as string | any;
    const project: Project = state.projects.projects.find(
      (el: Project) => el.id === data.projectId
    );
    updates[`/projects/${data.projectId}`] = {
      ...project,
      add_taxes: data.add_taxes,
    };
    return await update(dbref, updates).then(() => {
      return { projectId: data.projectId, add_taxes: data.add_taxes };
    });
  }
);

export const addNewProject = createAsyncThunk(
  "projects/addNewProject",
  async (project: Project) => {
    const newKey: string | null = push(child(dbref, "projects")).key;
    const updates = {} as string | any;
    updates[`/projects/${newKey}`] = project;
    return await update(dbref, updates).then(() => {
      if (newKey) project["id"] = newKey;
      return project;
    });
  }
);

export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async () => {
    return await get(child(dbref, "projects")).then((snapshot) => {
      const obj = snapshot.val();
      let projects: Project[] = [];
      for (let key in obj) {
        // if (obj[key].archive) continue
        projects.push({
          id: key,
          offer: obj[key].offer,
          add_taxes:
            obj[key].add_taxes === undefined ? false : obj[key].add_taxes,
          client_id: obj[key].client_id,
          title: obj[key].title,
          archive: obj[key].archive,
          tasks: obj[key].tasks === undefined ? [] : obj[key].tasks,
        });
      }
      return projects;
    });
  }
);

export const {} = projectsSlice.actions;
export default projectsSlice.reducer;
