import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { addNewProject } from "../../features/projects/projectsSlice"

export const CreateProject = () => {

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const clients = useAppSelector(state => state.clients.clients)
  const [title, setTitle] = useState("")
  const [clientId, setClientId] = useState("")
  const [offer, setOffer] = useState(false)
  const [addTaxes, setAddTaxes] = useState(false)

  const onHandleClient = (e: React.ChangeEvent<HTMLSelectElement>) => { setClientId(e.target.value) }
  const onHandleTitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => { setTitle(e.target.value) }
  const onHandleOffer = () => { setOffer(!offer) }
  const onHandleTaxes = () => { setAddTaxes(!addTaxes) }
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const project = {
      add_taxes: addTaxes,
      title: title,
      client_id: clientId,
      offer: offer,
      archive: false,
      tasks: []
    }

    dispatch(addNewProject(project))

    setTitle("")
    setClientId("")
    setOffer(false)
  }

  const disableSubmit = (): boolean => title === "" || clientId === ""

  return (
    <div className="relative">
      <div className="w-full h-full fixed bg-opacity inset-0"></div>
      <div className="transform w-full bg-lighter pb-32 z-50 bg-white shadow-outer rounded-lg px-4 py-2 flex flex-col items-center text-dark justify-between fixed bottom-0 left-0 transition duration-500 ease-in-out translate-y-0">
        <h4 className="font-hairline text-2xl mb-2">Нов проект</h4>
        <form onSubmit={onSubmit} className="w-full" >
          <fieldset>
            <textarea
              className="appearance-none block w-full bg-white border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-4"
              onChange={onHandleTitle}
              placeholder="Заглавие"
              required
              value={title}
            >
            </textarea>

            <div className="relative">
              <select
                onChange={onHandleClient}
                value={clientId}
                name="clientId"
                className="mb-4 block appearance-none w-full bg-white border py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option value="">Избери клиент</option>
                {
                  clients.map(client => (
                    <option
                      key={client.id}
                      value={client.id}
                    >
                      {client.name}
                    </option>
                  ))
                }
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>

            <label htmlFor="offer" className="pure-checkbox">
              <input
                id="offer"
                type="checkbox"
                className="w-auto"
                onChange={onHandleOffer}
              />
              Оферта
            </label>
            <label htmlFor="addTaxes" className="pure-checkbox ml-8">
              <input
                id="addTaxes"
                type="checkbox"
                className="w-auto"
                onChange={onHandleTaxes}
              />
              Отчисли данъците
            </label>
            <button
              disabled={disableSubmit()}
              type="submit"
              className={`z-50 fixed right-0 bottom-0 w-20 h-20 rounded-full text-white text-6xl mr-4 mb-8 flex justify-center items-center ${disableSubmit() ? " bg-dark" : " bg-green"}`}
            >
              <svg className="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 22">
                <path fill="#fff" d="M27 0L11.306 13.947 3.859 6.524 0 10.382 11.318 21.7 31 4.347 27 0z" />
              </svg>
            </button >
          </fieldset >
        </form >

        <button
          onClick={() => navigate(-1)}
          className="z-50 absolute right-0 top-0 w-10 h-10 rounded-full bg-dark text-white text-4xl mr-4 mb-8 flex justify-center items-center -mt-4"
        >
          <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="50.912" height="50.912" viewBox="0 0 50.912 50.912"><path fill="currentColor" d="m33.941 25.456 16.97-16.97L42.427 0l-16.97 16.97L8.486 0 0 8.485l16.97 16.97L0 42.427l8.485 8.486 16.97-16.97 16.971 16.97 8.486-8.486Z" /></svg>
        </button >
      </div >
    </div >
  )
}
