import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch } from "../app/hooks"
import { loggingIn } from "../features/auth/authSlice";

function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  type LocationState = {
    from: {
      pathname: string;
    }
  }
  const from = (location.state as LocationState)?.from?.pathname || '/'

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await dispatch(loggingIn({ email, password }))
    navigate(from, { replace: true })
  }

  return (
    <div className="flex pt-32 items-center flex-col">
      <h1 className="font-hairline text-2xl pb-4">Вписване</h1>
      <form onSubmit={onSubmit} className="flex flex-col gap-y-4">
        <input
          className="px-4 py-2 border-light border rounded"
          type="email"
          placeholder="Ел. поща"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <input
          className="px-4 py-2 border-light border rounded"
          type="password"
          placeholder="Парола"
          required
          value={password}
          onChange={e => setPassword(e.target.value)}
        />

        <button className="bg-blue text-white px-4 py-2 rounded hover:bg-blue-dark" type="submit">
          Впиши ме
        </button>
      </form>
    </div >
  )
}

export default Login
