import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ProjectsList } from "./Views/Projects/ProjectsList"
import { ClientsList } from "./Views/Clients/ClientsList"
import Login from "./Views/Login"
import Navbar from "./components/Navbar"
import NotFound from "./components/NotFound"
import { RequireAuth } from "./components/RequireAuth"
import { CreateProject } from "./Views/Projects/CreateProject"
import ProjectDetails from "./Views/Projects/ProjectDetails"
import TaskForm from "./Views/Projects/TaskForm"
import Loading from "./components/Loading"
import { useAppSelector } from "./app/hooks"
import { useEffect } from "react"
import { fetchProjects } from './features/projects/projectsSlice'
import { fetchClients } from './features/clients/clientsSlice'
import { store } from "./app/store"
import { CreateClient } from "./Views/Clients/CreateClient"
import ClientDetails from "./Views/Clients/ClientDetails"

function App() {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn)
  const loading = useAppSelector((state) => state.projects.loading || state.clients.loading)

  useEffect(() => {
    if (isLoggedIn) {
      store.dispatch(fetchProjects())
      store.dispatch(fetchClients())
    }
  }, [isLoggedIn])

  return (
    <Router>
      <Loading loading={loading} />
      <Navbar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<RequireAuth />}>
          <Route path="/" element={<ProjectsList />} >
            <Route path="create" element={<CreateProject />} />
          </Route>
          <Route path="/project/:id" element={<ProjectDetails />}>
            <Route path={`/project/:id/task/create`} element={<TaskForm />} />
            <Route path={`/project/:id/task/:idx`} element={<TaskForm />} />
          </Route>
          <Route path="/clients" element={<ClientsList />} >
            <Route path="create" element={<CreateClient />} />
          </Route>
          <Route path="/client/:id" element={<ClientDetails />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router >
  );
}

export default App;
